import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import axios from "axios";
import reportWebVitals from "./reportWebVitals";
import { ApolloProvider } from "@apollo/react-hooks";
import Bugsnag from "@bugsnag/js";
import BugsnagPluginReact from "@bugsnag/plugin-react";
import clientV3 from "./apolloClient";
import "./i18next";

import "@fontsource/roboto";
import "@fontsource/poppins";
import "@fontsource/lato";

const root = ReactDOM.createRoot(document.getElementById("root"));

Bugsnag.start({
  logger: null,
  enabledReleaseStages: ["production"],
  releaseStage: process.env.REACT_APP_TRACK_RELEASE_STAGE,
  apiKey: process.env.REACT_APP_TRACK_TOKEN,
  plugins: [new BugsnagPluginReact()],
});

axios.defaults.baseURL = process.env.REACT_APP_BASE_URL;

axios.interceptors.response.use(
  function (response) {
    // Do something with response data
    return response;
  },
  function (error) {
    // Do something with response error
    Bugsnag.notify(error);
    return Promise.reject(error);
  },
);

// if (window.location.pathname.includes("in-web")) {
//   let script = document.createElement("script");
//   script.setAttribute("src", "https://plausible.io/js/plausible.js");
//   script.setAttribute("type", "text/javascript");
//   script.setAttribute("data-domain", "flymya.com");
//   document.head.appendChild(script);
// }
root.render(
  <ApolloProvider client={clientV3()}>
    <App />
  </ApolloProvider>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
