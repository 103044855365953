import React, { useState, useEffect, useRef } from "react";
import { Button, CircularProgress, Grid } from "@mui/material";
import { withFormik } from "formik";
import { compose } from "recompose";
import PhonePrefix from "../../components/inputs/PhonePrefix";
import DayOptionComponent from "../../components/inputs/DayOptionComponent";
import MonthOptionComponent from "../../components/inputs/MonthOptionComponent";
import YearOptionComponent from "../../components/inputs/YearOptionComponent";
import { COUNTRIES } from "../../config/data/v3countries";
import { DivisionList, CardList } from "../../config/data/nrcList";
import {
  MYANMAR_ID,
  YANGON_REGION,
  YANGON_CITY,
  IDENTIFIER,
  LOGIN_VIA_KBZPAY,
  LOGIN_PHONE,
} from "../../config/constants";
import "../../assets/scss/Home.scss";
import OTP_LOGIN_EMAIL_REGEX from "../../config/regex/otpLoginEmailRegex";
import ValidationMethod from "./ValidationMethod";
import ProfileEditForm from "./ProfileEditForm";
import { useTranslation } from "react-i18next";
import MediaQuery from "react-responsive";
import MoreInformations from "./MoreInformations";
import useMoreInformationHook from "../../hooks/MoreInformationHook";

// eslint-disable-next-line no-unused-vars
let socialLogin = false;
let pageName = "home";

const SignupForm = ({
  errorMsg,
  isDim,
  values,
  page,
  isSpinner,
  handleSubmit,
  setFieldValue,
  touched,
  errors,
  formError,
  setFormError,
  regValues,
  isSocialLogin,
  socialLoginType,
  val,
  data,
  addId,
  campaignId,
  setIsShow,
}) => {
  const [flag, setFlag] = useState(false);
  const [date] = useState(false);
  const [checkNRC, setCheckNRC] = useState(false);
  const [isSubmitClick, setIsSubmitClick] = useState(false);
  const { t } = useTranslation();
  const phoneNumberRef = useRef(null);
  const lastNameRef = useRef(null);

  socialLogin = isSocialLogin;

  pageName = page;

  const {
    getCity,
    getTownship,
    regionArr,
    cityArr,
    setCityArr,
    townshipArr,
    setTownshipArr,
    chooseList,
    listArr,
    setDefaultList,
    setSelectedDivison,
    setSelectedList,
    setSelectedCardList,
    setSelectedNrcNo,
    selectedDivision,
    selectedList,
    selectedCardList,
    selectedNrcNo,
    handleNrc,
    getRegionTownshipList,
  } = useMoreInformationHook({ setFieldValue });

  useEffect(() => {
    if (!flag) {
      let val =
        regValues && regValues.adultInfo && regValues.adultInfo.length > 0
          ? regValues.adultInfo[0]
          : null;

      getRegionTownshipList(val);

      let isCheck =
        values.signupNrc !== "" && values.signupNrc.indexOf("/") !== -1
          ? true
          : false;
      if (isCheck) {
        chooseList(values.signupNrc.split("/")[0]);
        setSelectedDivison(values.signupNrc.split("/")[0]);
        setSelectedList(
          values.signupNrc.substring(
            values.signupNrc.indexOf("/") + 1,
            values.signupNrc.lastIndexOf("("),
          ),
        );
        setSelectedCardList(
          values.signupNrc.substring(
            values.signupNrc.indexOf("(") + 1,
            values.signupNrc.lastIndexOf(")"),
          ),
        );
        setSelectedNrcNo(values.signupNrc.split(")")[1]);
      }

      setFlag(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    flag,
    regValues,
    values,
    values.nrc,
    values.signupNrcPass,
    values.singupNrc,
  ]);

  useEffect(() => {
    if (val) {
      setFieldValue("signupPrefix", val?.gender);
      setFieldValue("signupFirstName", val?.firstName);
      setFieldValue("signupLastName", val?.lastName);
      setFieldValue("signupEmail", val?.email);
      setFieldValue("signupPhone", val?.mobileNo);
      setFieldValue("signupPhonePrefix", val?.mobilePrefix);
    }
  }, [setFieldValue, val]);

  useEffect(() => {
    if (data) {
      setFieldValue(
        "signupPrefix",
        data?.prefix ? data.prefix.replace(".", "") : "Mr",
      );
      setFieldValue("signupFirstName", data?.first_name);
      setFieldValue("signupLastName", data?.last_name);
      setFieldValue("signupEmail", data?.email);
      setFieldValue(
        "signupPhonePrefix",
        data?.phone?.replace(" ", "")?.substring(0, 2),
      );
      setFieldValue("signupPhone", data?.phone?.replace(" ", "")?.substring(2));
      setFieldValue("signupDobDay", data?.dob?.split("-")[2]);
      setFieldValue("signupDobMonth", data?.dob?.split("-")[1]);
      setFieldValue("signupDobYear", data?.dob?.split("-")[0]);
      setFieldValue("signupNationality", data?.nationality?.id);
      setFieldValue("signupNrcPass", data?.passport);
      setFieldValue("signupExpDay", data?.passport_exp?.split("-")[2]);
      setFieldValue("signupExpMonth", data?.passport_exp?.split("-")[1]);
      setFieldValue("signupExpYear", data?.passport_exp?.split("-")[0]);
      setFieldValue("address", data?.address);
      // setSelectedDivison(data?.nrc?.split("/")[0]);
      // chooseList(data?.nrc?.split("/")[0]);
      // setSelectedList(
      //   data?.nrc?.substring(
      //     data?.nrc?.indexOf("/") + 1,
      //     data?.nrc?.lastIndexOf("("),
      //   ),
      // );
      // setSelectedCardList(
      //   data?.nrc?.substring(
      //     data?.nrc?.indexOf("(") + 1,
      //     data?.nrc?.lastIndexOf(")"),
      //   ),
      // );
      // setSelectedNrcNo(
      //   data?.nrc?.split(")")[1] !== "undefined"
      //     ? data?.nrc?.split(")")[1]
      //     : "",
      // );

      setFieldValue("township", data?.township?.id);
      setFieldValue("city", data?.township?.city?.id);
      setFieldValue(
        "signupNrc",
        data?.nrc?.split("/")[0] +
          "/" +
          data?.nrc?.substring(
            data?.nrc?.indexOf("/") + 1,
            data?.nrc?.lastIndexOf("("),
          ) +
          "(" +
          data?.nrc?.substring(
            data?.nrc?.indexOf("(") + 1,
            data?.nrc?.lastIndexOf(")"),
          ) +
          ")" +
          data?.nrc?.split(")")[1],
      );
      setSelectedDivison(data?.nrc?.split("/")[0]);
      chooseList(data?.nrc?.split("/")[0]);
      setSelectedList(
        data?.nrc?.substring(
          data?.nrc?.indexOf("/") + 1,
          data?.nrc?.lastIndexOf("("),
        ),
      );
      setSelectedCardList(
        data?.nrc?.substring(
          data?.nrc?.indexOf("(") + 1,
          data?.nrc?.lastIndexOf(")"),
        ),
      );
      setSelectedNrcNo(
        data?.nrc?.split(")")[1] !== "undefined"
          ? data?.nrc?.split(")")[1]
          : "",
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (page === "home") {
      setFieldValue(
        "signupEmail",
        localStorage.getItem("login_email") !== "undefined" &&
          socialLoginType !== "phone"
          ? localStorage.getItem("login_email")
          : "",
      );
      setFieldValue(
        "signupFirstName",
        localStorage.getItem("login_name") !== "undefined" &&
          localStorage.getItem("login_name") !== "null"
          ? localStorage.getItem("login_name")
          : "",
      );
      setFieldValue(
        "signupPhone",
        localStorage.getItem(LOGIN_PHONE) !== "undefined" &&
          localStorage.getItem(LOGIN_PHONE) !== ""
          ? localStorage.getItem(LOGIN_PHONE)
          : "",
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setIsSubmitClick(false);
  }, []);

  return (
    <div
      className={isDim ? "dim-div create-account-form" : "create-account-form"}
    >
      <Grid container>
        <Grid item={true} md={2}>
          <label>
            {t("travelServices.travel_services_prefix") !==
            "travelServices.travel_services_prefix"
              ? t("travelServices.travel_services_prefix") + " *"
              : "Title *"}
          </label>
          <select
            className="selectBox selectBoxFullWidthSignUpMobile"
            disabled={val?.gender || date ? true : false}
            name="signupPrefix"
            value={values.signupPrefix}
            onChange={(e) => setFieldValue("signupPrefix", e.target.value)}
          >
            <option value="Mr">Mr.</option>
            <option value="Mrs">Mrs.</option>
            <option value="Ms">Ms.</option>
          </select>
        </Grid>
        <MediaQuery minDeviceWidth={780}>
          <Grid item={true} xs={12} sm={12} md={5} className="first_name">
            <label>
              {t("travelServices.travel_services_customer_name") !==
              "travelServices.travel_services_customer_name"
                ? t("travelServices.travel_services_customer_name")
                : "First Name + Middle Name *"}
            </label>
            <input
              className={
                touched.signupFirstName && errors.signupFirstName
                  ? "error-textbox"
                  : ""
              }
              disabled={val?.firstName || date ? true : false}
              name="signupFirstName"
              value={values.signupFirstName ? values.signupFirstName : ""}
              onChange={(e) => setFieldValue("signupFirstName", e.target.value)}
              data-lpignore="true"
              required
            />
            {touched.signupFirstName && errors.signupFirstName && (
              <p className="signup-error">{errors.signupFirstName}</p>
            )}
          </Grid>
        </MediaQuery>
        <MediaQuery maxWidth={780}>
          <Grid
            item={true}
            xs={8.5}
            sm={8.5}
            md={5}
            className="first_name"
            style={{
              marginLeft: "2px",
            }}
          >
            <label>
              {t("travelServices.travel_services_customer_name") !==
              "travelServices.travel_services_customer_name"
                ? t("travelServices.travel_services_customer_name")
                : "First Name + Middle Name *"}
            </label>
            <input
              className={
                touched.signupFirstName && errors.signupFirstName
                  ? "error-textbox"
                  : ""
              }
              disabled={val?.firstName || date ? true : false}
              name="signupFirstName"
              value={values.signupFirstName ? values.signupFirstName : ""}
              onChange={(e) => setFieldValue("signupFirstName", e.target.value)}
              data-lpignore="true"
              required
            />
            {touched.signupFirstName && errors.signupFirstName && (
              <p className="signup-error">{errors.signupFirstName}</p>
            )}
          </Grid>
        </MediaQuery>
        <Grid item={true} xs={12} sm={12} md={5} className="last_name">
          <label>
            {t("travelServices.travel_services_customer_last_name") !==
            "travelServices.travel_services_customer_last_name"
              ? t("travelServices.travel_services_customer_last_name")
              : "Last Name *"}
          </label>
          <input
            className={
              touched.signupLastName && errors.signupLastName
                ? "error-textbox"
                : ""
            }
            name="signupLastName"
            disabled={val?.lastName || date ? true : false}
            value={values.signupLastName}
            onChange={(e) => setFieldValue("signupLastName", e.target.value)}
            data-lpignore="true"
            required
            ref={lastNameRef}
            onKeyDown={(event) => {
              if (event.key === "Tab") {
                event.preventDefault();
                if (lastNameRef.current) {
                  phoneNumberRef.current.focus();
                }
              }
            }}
          />
          {touched.signupLastName && errors.signupLastName && (
            <p className="signup-error">{errors.signupLastName}</p>
          )}
        </Grid>

        {pageName !== "addressBook" && pageName !== "profile" && (
          <>
            <Grid item xs={4} sm={3} md={3} className="phone-container">
              <label>
                {t("travelServices.travel_services_customer_phone") !==
                "travelServices.travel_services_customer_phone"
                  ? t("travelServices.travel_services_customer_phone")
                  : "Phone *"}
              </label>
              <PhonePrefix
                val={val}
                name="signupPhonePrefix"
                value={
                  sessionStorage.getItem(LOGIN_VIA_KBZPAY) &&
                  JSON.parse(sessionStorage.getItem(LOGIN_VIA_KBZPAY))?.phone
                    ? sessionStorage.getItem(LOGIN_VIA_KBZPAY) &&
                      JSON.parse(sessionStorage.getItem(LOGIN_VIA_KBZPAY))
                        ?.phone
                    : values.signupPhonePrefix || ""
                }
                prefixValue={values.signupPhonePrefix}
                handlePhonePrefix={(value) =>
                  setFieldValue("signupPhonePrefix", value)
                }
                handlePhoneFlag={(value) =>
                  setFieldValue("mobileCountryFlag", value)
                }
                mobileCountryFlag={values.mobileCountryFlag}
                isDisable={
                  socialLoginType === "phone" ||
                  (localStorage.getItem(IDENTIFIER) &&
                    localStorage.getItem(IDENTIFIER) !== "" &&
                    localStorage.getItem(IDENTIFIER).includes("+"))
                }
                showSpanLabel={false}
                dropdownStyle={{
                  background: "white",
                  border: "none",
                }}
              />
            </Grid>
            <Grid item={true} xs={8} sm={9} md={9}>
              <label>&nbsp;</label>
              <input
                ref={phoneNumberRef}
                className={
                  touched.signupPhone && errors.signupPhone
                    ? "error-textbox signup-box-phone"
                    : "signup-box-phone"
                }
                disabled={
                  (val !== undefined && val?.mobileNo) || date
                    ? true
                    : socialLoginType === "phone"
                }
                name="signupPhone"
                value={
                  sessionStorage.getItem(LOGIN_VIA_KBZPAY) &&
                  JSON.parse(sessionStorage.getItem(LOGIN_VIA_KBZPAY))?.user
                    ?.arranger?.phone
                    ? sessionStorage.getItem(LOGIN_VIA_KBZPAY) &&
                      JSON.parse(sessionStorage.getItem(LOGIN_VIA_KBZPAY))?.user
                        ?.arranger?.phone
                    : localStorage.getItem(LOGIN_PHONE) !== "undefined" &&
                      localStorage.getItem(LOGIN_PHONE) !== ""
                    ? localStorage.getItem(LOGIN_PHONE)
                    : values.signupPhone
                }
                onChange={(e) => setFieldValue("signupPhone", e.target.value)}
                data-lpignore="true"
                required
              />
              {touched.signupPhone && errors.signupPhone && (
                <p className="signup-error">{errors.signupPhone}</p>
              )}
            </Grid>
          </>
        )}

        {pageName !== "addressBook" && pageName !== "profile" && (
          <Grid item={true} xs={12} sm={12} md={12} className="email_input">
            <label>
              {t("travelServices.travel_services_customer_email") !==
              "travelServices.travel_services_customer_email"
                ? t("travelServices.travel_services_customer_email")
                : "Email *"}
            </label>
            <input
              className={
                (touched.signupEmail && errors.signupEmail) || formError !== ""
                  ? "error-textbox"
                  : ""
              }
              disabled={
                val?.email
                  ? true
                  : pageName === "addressBook"
                  ? false
                  : socialLoginType === "google" ||
                    pageName === "profile" ||
                    (localStorage.getItem(IDENTIFIER) &&
                      localStorage.getItem(IDENTIFIER) !== "" &&
                      localStorage.getItem(IDENTIFIER) !== "undefined" &&
                      !localStorage.getItem(IDENTIFIER).includes("+"))
                  ? true
                  : false
              }
              name="signupEmail"
              value={
                localStorage.getItem(IDENTIFIER) &&
                localStorage.getItem(IDENTIFIER) !== "undefined" &&
                socialLoginType !== "phone"
                  ? localStorage.getItem(IDENTIFIER)
                  : values.signupEmail
                  ? values.signupEmail
                  : ""
              }
              onChange={(e) => {
                setFormError("");
                setFieldValue("signupEmail", e.target.value);
              }}
              data-lpignore="true"
              required
            />
            {touched.signupEmail && errors.signupEmail && (
              <p className="signup-error">{errors.signupEmail}</p>
            )}
            {formError !== "" && <p className="signup-error">{formError}</p>}
          </Grid>
        )}

        {!isSocialLogin &&
          pageName !== "profile" &&
          pageName !== "addressBook" &&
          pageName !== "emailLinkAuth" && (
            <>
              <Grid item={true} xs={12} sm={12} md={12} className="password">
                <label>
                  {t("masterPassengerInformationTwo.password") !==
                  "masterPassengerInformationTwo.password"
                    ? t("masterPassengerInformationTwo.password")
                    : "Password"}
                </label>
                <input
                  className={
                    touched.signupPassword && errors.signupPassword
                      ? "error-textbox"
                      : ""
                  }
                  name="signupPassword"
                  type="password"
                  value={values.signupPassword}
                  autoComplete="off"
                  onChange={(e) =>
                    setFieldValue("signupPassword", e.target.value)
                  }
                  data-lpignore="true"
                  required={isSocialLogin ? false : true}
                />
                {touched.signupPassword && errors.signupPassword && (
                  <p className="signup-error">{errors.signupPassword}</p>
                )}
              </Grid>
            </>
          )}

        {campaignId !== "" && (
          <Grid item={true} sm={12} md={12}>
            <label>Camapign ID</label>
            <input
              className={
                touched.signupPassword && errors.signupPassword
                  ? "error-textbox"
                  : ""
              }
              style={{ color: "grey" }}
              name="signupPassword"
              value={campaignId}
              autoComplete="off"
              readOnly
              data-lpignore="true"
            />
          </Grid>
        )}

        {pageName === "addressBook" && (
          <>
            <Grid
              item={true}
              xs={12}
              sm={12}
              md={12}
              className="dobRegion addressBookDob"
            >
              <label>
                {t("masterPassengerInformation.dob") !==
                "masterPassengerInformation.dob"
                  ? t("masterPassengerInformation.dob") + " *"
                  : "Date of Birth *"}
              </label>
              <Grid container className="dobSelects">
                <Grid
                  item={true}
                  md={3}
                  style={{
                    width: "70px",
                  }}
                >
                  <select
                    name="signupDobDay"
                    className={
                      values.signupDobDay === "00"
                        ? "selectBox placeHolderText"
                        : "selectBox"
                    }
                    required
                    disabled={date ? true : false}
                    value={values.signupDobDay}
                    onChange={(e) =>
                      setFieldValue("signupDobDay", e.target.value)
                    }
                  >
                    <DayOptionComponent
                      dayOption={values.signupDobMonth === "02" ? 29 : 31}
                    />
                  </select>
                  {isSubmitClick && values.signupDobDay === "00" && (
                    <p className="signup-error">Birth day is required</p>
                  )}
                </Grid>
                <Grid
                  item={true}
                  md={5}
                  style={{
                    width: "110px",
                  }}
                >
                  <select
                    name="signupDobMonth"
                    disabled={date ? true : false}
                    className={
                      values.signupDobMonth === "00"
                        ? "selectBox placeHolderText"
                        : "selectBox"
                    }
                    required
                    value={values.signupDobMonth}
                    onChange={(e) =>
                      setFieldValue("signupDobMonth", e.target.value)
                    }
                  >
                    <MonthOptionComponent />
                  </select>
                  {isSubmitClick && values.signupDobMonth === "00" && (
                    <p className="signup-error">Birth month is required.</p>
                  )}
                </Grid>
                <Grid
                  item={true}
                  md={4}
                  style={{
                    width: "90px",
                  }}
                >
                  <select
                    disabled={date}
                    required
                    name="signupDobYear"
                    className={
                      values.signupDobYear === "00"
                        ? "selectBox placeHolderText"
                        : "selectBox"
                    }
                    value={values.signupDobYear}
                    onChange={(e) =>
                      setFieldValue("signupDobYear", e.target.value)
                    }
                  >
                    <YearOptionComponent type="dob" />
                  </select>
                  {isSubmitClick && values.signupDobYear === "0000" && (
                    <p className="signup-error">Birth year is required.</p>
                  )}
                </Grid>
              </Grid>
            </Grid>
            <Grid item={true} sm={12} md={12}>
              <label>
                {t(`b2c.nationality`) !== "b2c.nationality"
                  ? t(`b2c.nationality`) + " *"
                  : "Nationality *"}
              </label>
              <select
                className="nationality"
                name="signupNationality"
                disabled={date}
                value={values.signupNationality}
                onChange={(e) =>
                  setFieldValue("signupNationality", e.target.value)
                }
              >
                {COUNTRIES.map((country, i) => {
                  return (
                    <option key={i} value={country.CountryId}>
                      {country.CountryName}
                    </option>
                  );
                })}
              </select>
            </Grid>
            {Number(values.signupNationality) === MYANMAR_ID && (
              <Grid
                item={true}
                xs={12}
                sm={12}
                md={12}
                className="nrcRegion addressBookNrc"
              >
                <label>
                  {t(`b2c.nrc`) !== "b2c.nrc" ? t(`b2c.nrc`) + " *" : "NRC *"}
                </label>
                <Grid container className="nrcSelects">
                  <Grid item={true} xs={3} sm={3} md={2}>
                    <select
                      name="nrcDivision"
                      disabled={date}
                      defaultValue={5}
                      className="selectBox nrcNumberField"
                      value={selectedDivision}
                      onChange={(e) => {
                        setSelectedDivison(e.target.value);
                        setDefaultList(e.target.value);
                        chooseList(e.target.value);
                      }}
                    >
                      {DivisionList.map((division, key) => {
                        return (
                          <option key={key} value={division}>
                            {division}
                          </option>
                        );
                      })}
                    </select>
                  </Grid>
                  <Grid item={true} xs={5} sm={5} md={4}>
                    <select
                      name="nrcList"
                      disabled={date}
                      className="selectBox nrcTextField"
                      value={selectedList}
                      onChange={(e) => {
                        setSelectedList(e.target.value);
                        handleNrc(
                          selectedDivision,
                          e.target.value,
                          selectedCardList,
                          selectedNrcNo,
                        );
                      }}
                    >
                      {listArr &&
                        listArr.map((list, key) => {
                          return (
                            <option key={key} value={list}>
                              {list}
                            </option>
                          );
                        })}
                    </select>
                  </Grid>
                  <Grid item={true} xs={4} sm={4} md={2}>
                    <select
                      disabled={date}
                      name="nrcCard"
                      className="selectBox nrcNumberField"
                      value={selectedCardList}
                      onChange={(e) => {
                        setSelectedCardList(e.target.value);
                        handleNrc(
                          selectedDivision,
                          selectedList,
                          e.target.value,
                          selectedNrcNo,
                        );
                      }}
                    >
                      {CardList.map((card, key) => {
                        return (
                          <option key={key} value={card}>
                            {card}
                          </option>
                        );
                      })}
                    </select>
                  </Grid>
                  <Grid item={true} xs={12} sm={12} md={4}>
                    <input
                      name="nrcNumber"
                      disabled={date}
                      className="nrcNumber"
                      value={selectedNrcNo}
                      autoComplete="off"
                      onChange={(e) => {
                        if (!/^[0-9\b]*$/.test(e.target.value)) {
                          setCheckNRC(true);
                          return;
                        }
                        setCheckNRC(false);
                        setSelectedNrcNo(e.target.value);
                        handleNrc(
                          selectedDivision,
                          selectedList,
                          selectedCardList,
                          e.target.value,
                        );
                      }}
                      data-lpignore="true"
                    />
                    {checkNRC && (
                      <p className="signup-error">Please type only number.</p>
                    )}
                    {isSubmitClick && selectedNrcNo === "" && (
                      <p className="signup-error">NRC is required.</p>
                    )}
                  </Grid>
                </Grid>
              </Grid>
            )}
          </>
        )}
        {pageName === "addressBook" && (
          <>
            <Grid
              item={true}
              xs={12}
              sm={6}
              md={6}
              className="nrcRegion addressBookNrc"
            >
              <label>
                {t(`b2c.passport`) !== "b2c.passport"
                  ? t(`b2c.passport`) + " *"
                  : "Passport"}
              </label>
              <Grid item={true} sm={12} md={12}>
                <input
                  className={
                    touched.signupNrcPass && errors.signupNrcPass
                      ? "error-textbox nrcNumber"
                      : "nrcNumber"
                  }
                  name="signupNrcPass"
                  disabled={date}
                  type="text"
                  value={values.signupNrcPass}
                  autoComplete="off"
                  onChange={(e) =>
                    setFieldValue("signupNrcPass", e.target.value)
                  }
                  data-lpignore="true"
                  required
                />
                {touched.signupNrcPass && errors.signupNrcPass && (
                  <p className="signup-error">{errors.signupNrcPass}</p>
                )}
              </Grid>
            </Grid>
            <Grid
              item={true}
              sm={6}
              md={6}
              className="nrcRegion addressBookNrc"
            >
              <label>
                {t(`b2c.passport_expiration_date`) !==
                "b2c.passport_expiration_date"
                  ? `${t(`b2c.passport_expiration_date`)} *`
                  : "Passport Expiration Date"}
              </label>
              <Grid container className="passSelects">
                <Grid
                  item={true}
                  md={3}
                  style={{
                    width: "70px",
                  }}
                >
                  <select
                    disabled={date}
                    name="signupExpDay"
                    className={
                      values.signupExpDay === "00"
                        ? "selectBox placeHolderText"
                        : "selectBox"
                    }
                    value={values.signupExpDay}
                    onChange={(e) =>
                      setFieldValue("signupExpDay", e.target.value)
                    }
                  >
                    <DayOptionComponent
                      dayOption={values.signupExpMonth === "02" ? 29 : 31}
                    />
                    {/* {passExpErr?.error && (
                            <p className="signup-error">
                              {passExpErr?.errorMessage}
                            </p>
                          )} */}
                  </select>
                  {touched.signupExpDay && errors.signupExpDay && (
                    <p className="signup-error">{errors.signupExpDay}</p>
                  )}
                </Grid>
                <Grid
                  item={true}
                  md={5}
                  style={{
                    width: "110px",
                  }}
                >
                  <select
                    name="signupExpMonth"
                    disabled={date}
                    className={
                      values.signupExpMonth === "00"
                        ? "selectBox placeHolderText"
                        : "selectBox"
                    }
                    value={values.signupExpMonth}
                    onChange={(e) =>
                      setFieldValue("signupExpMonth", e.target.value)
                    }
                  >
                    <MonthOptionComponent />
                  </select>
                  {touched.signupExpMonth && errors.signupExpMonth && (
                    <p className="signup-error">{errors.signupExpMonth}</p>
                  )}
                </Grid>
                <Grid
                  item={true}
                  md={4}
                  style={{
                    width: "90px",
                  }}
                >
                  <select
                    name="signupExpYear"
                    disabled={date}
                    className={
                      values.signupExpYear === "00"
                        ? "selectBox placeHolderText"
                        : "selectBox"
                    }
                    value={values.signupExpYear}
                    onChange={(e) =>
                      setFieldValue("signupExpYear", e.target.value)
                    }
                  >
                    <YearOptionComponent type="exp" />
                  </select>
                  {touched.signupExpYear && errors.signupExpYear && (
                    <p className="signup-error">{errors.signupExpYear}</p>
                  )}
                </Grid>
              </Grid>
            </Grid>
          </>
        )}

        {/* {!isSocialLogin && (
          <Grid item={true} xs={12} sm={12} md={6}>
            <label>Confirm Password *</label>
            <input
              className={
                touched.signupConfirmPassword && errors.signupConfirmPassword
                  ? "error-textbox"
                  : ""
              }
              name="signupConfirmPassword"
              type="password"
              value={values.signupConfirmPassword}
              autoComplete="off"
              onChange={e =>
                setFieldValue("signupConfirmPassword", e.target.value)
              }
              data-lpignore="true"
              required={isSocialLogin ? false : true}
            />
            {touched.signupConfirmPassword && errors.signupConfirmPassword && (
              <p className="signup-error">{errors.signupConfirmPassword}</p>
            )}
          </Grid>
        )} */}
        {pageName === "profile" && (
          <ProfileEditForm
            values={values}
            date={date}
            setFieldValue={setFieldValue}
            selectedDivision={selectedDivision}
            setSelectedDivison={setSelectedDivison}
            setDefaultList={setDefaultList}
            chooseList={chooseList}
            selectedList={selectedList}
            setSelectedList={setSelectedList}
            handleNrc={handleNrc}
            selectedNrcNo={selectedNrcNo}
            selectedCardList={selectedCardList}
            setSelectedCardList={setSelectedCardList}
            checkNRC={checkNRC}
            setCheckNRC={setCheckNRC}
            setSelectedNrcNo={setSelectedNrcNo}
            DivisionList={DivisionList}
            listArr={listArr}
            CardList={CardList}
            touched={touched}
            errors={errors}
            getCity={getCity}
            getTownship={getTownship}
            regionArr={regionArr}
            cityArr={cityArr}
            townshipArr={townshipArr}
          />
        )}

        {pageName !== "profile" && (
          <MoreInformations
            pageName={pageName}
            values={values}
            setFieldValue={setFieldValue}
            date={date}
            selectedDivision={selectedDivision}
            setSelectedDivison={setSelectedDivison}
            setDefaultList={setDefaultList}
            chooseList={chooseList}
            selectedList={selectedList}
            setSelectedList={setSelectedList}
            handleNrc={handleNrc}
            selectedCardList={selectedCardList}
            selectedNrcNo={selectedNrcNo}
            listArr={listArr}
            setSelectedCardList={setSelectedCardList}
            setCheckNRC={setCheckNRC}
            setSelectedNrcNo={setSelectedNrcNo}
            checkNRC={checkNRC}
            touched={touched}
            errors={errors}
            getCity={getCity}
            getTownship={getTownship}
            regionArr={regionArr}
            cityArr={cityArr}
            setCityArr={setCityArr}
            townshipArr={townshipArr}
            setTownshipArr={setTownshipArr}
          />
        )}
      </Grid>
      {/* {pageName === "profile" && date ? (
        <p style={{ textAlign: "center", color: "red" }}>
          You have to wait 7 days for next attempt.
        </p>
      ) : (
        ""
      )} */}
      <p style={{ textAlign: "center" }}>{errorMsg}</p>
      {addId === "addressBookCreate" || addId === "addressBookEdit" ? (
        <div className="addreBookBtn">
          <button
            disabled={checkNRC ? checkNRC : date}
            onClick={() => {
              setIsShow && setIsShow(false);
            }}
            style={{
              color: "#04adef",
              backgroundColor: "white",
              marginRight: 10,
              width: 100,
            }}
          >
            {"Cancel"}
          </button>
          <button
            disabled={checkNRC ? checkNRC : date}
            onClick={() => {
              setIsSubmitClick(true);
              handleSubmit();
            }}
            style={{
              backgroundColor: "#04adef",
              color: "white",
              width: 100,
            }}
          >
            {"Save"}
            {isSpinner && <CircularProgress />}
          </button>
        </div>
      ) : (
        <Button
          color="primary"
          variant="contained"
          disabled={checkNRC ? checkNRC : date}
          type="button"
          className="signup-btn"
          id="btn-sign-in"
          onClick={() => {
            setIsSubmitClick(true);
            handleSubmit();
          }}
        >
          {pageName === "profile"
            ? "Save"
            : t("masterPassengerInformationTwo.sign_up") !==
              "masterPassengerInformationTwo.sign_up"
            ? t("masterPassengerInformationTwo.sign_up")
            : "Sign Up"}{" "}
          {isSpinner && <CircularProgress />}
        </Button>
      )}
    </div>
  );
};

export default compose(
  withFormik({
    mapPropsToValues: (props) => {
      let val =
        props.regValues &&
        props.regValues.adultInfo &&
        props.regValues.adultInfo.length > 0
          ? props.regValues.adultInfo[0]
          : null;

      return {
        id:
          localStorage.getItem("arranger_people_id") &&
          localStorage.getItem("arranger_people_id") !== ""
            ? localStorage.getItem("arranger_people_id")
            : props?.addId === "addressBookEdit"
            ? props?.data?.id
            : null,
        signupPrefix: val && val.title ? val.title : "Mr",
        signupFirstName: val && val.firstName ? val.firstName : "",
        signupLastName: val && val.lastName ? val.lastName : "",
        signupEmail:
          val && val.email && !OTP_LOGIN_EMAIL_REGEX.test(val.email)
            ? val.email
            : "",
        signupPassword: "",
        mobileCountryFlag:
          val && val.mobileCountryFlag ? val.mobileCountryFlag : "mm",
        signupPhonePrefix: val && val.mobilePrefix ? val.mobilePrefix : "95",
        signupPhone: val && val.mobileNo ? val.mobileNo : "",
        signupDobDay: val && val.dobDay ? val.dobDay : "00",
        signupDobMonth: val && val.dobMonth ? val.dobMonth : "00",
        signupDobYear: val && val.dobYear ? val.dobYear : "0000",
        signupExpDay: val && val.expDay ? val.expDay : "00",
        signupExpMonth: val && val.expMonth ? val.expMonth : "00",
        signupExpYear: val && val.expYear ? val.expYear : "0000",
        signupNationality: MYANMAR_ID,
        signupNrcPass: val && val.passportNrc ? val.passportNrc : "",
        signupNrc: val && val.nrc ? val.nrc : "",
        region: val && val.regionId ? val.regionId : YANGON_REGION,
        city: val && val.cityId ? val.cityId : YANGON_CITY,
        township: val && val.townshipId ? val.townshipId : null,
        address: val && val.address ? val.address : "",
      };
    },
    validationSchema: (props) => ValidationMethod(props),
    handleSubmit(values, { props }) {
      props.setIsSpinner(true);
      props.handleSignUp(values);
    },
  }),
)(SignupForm);
